var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('PageHeader',{attrs:{"title":_vm.$tc('app.user', 2)}}),_c('div',{staticClass:"page-content container-fluid pb-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"users-column",class:{
          'col-lg-7': _vm.$store.state.plans.omniPlan,
          'col-lg-12': !_vm.$store.state.plans.omniPlan,
        }},[_c('users-card',{ref:"usersCard"})],1),(_vm.$store.state.plans.omniPlan)?_c('div',{staticClass:"users-column",class:{
          'col-lg': _vm.$store.state.plans.omniPlan,
          'col-lg-12': !_vm.$store.state.plans.omniPlan,
        }},[_c('departments-card',{attrs:{"data":_vm.users},on:{"departments":function($event){return _vm.updateUserCards()}}})],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }